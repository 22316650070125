export const environment = {
  name: 'dev',
  production: false,
  defaultLanguage: "en",
  backendBaseUrl: "https://dnekcabdev.burningleads.io",
  contactEmail: "contact@burningleads.io",
  recaptchaSiteKey: "",
  auth: {
    domain: "burningleads-dev.eu.auth0.com",
    clientId: "mQH7TKxvrDZu9Vq44QemAvR4TqdssXvl",
    // redirectURI: 'http://localhost:4200/auth/call-back',
    redirectURI: 'https://dnetnorfdev.burningleads.io/auth/call-back',
    responseType: 'token id_token',
    scope: 'openid email profile',
    apiIdentifier: 'https://api.burningleads-dev.com',
    realm: 'Username-Password-Authentication',
    // logoutReturnTo: 'http://localhost:4200'
    logoutReturnTo: 'https://dnetnorfdev.burningleads.io'
  },
  firebaseConfig: {
    apiKey: "AIzaSyDGca9H1dCFwTzc_Hjc9YLQEl61KAXlq5M",
    authDomain: "burningleads-dev.firebaseapp.com",
    projectId: "burningleads-dev",
    storageBucket: "burningleads-dev.appspot.com",
    messagingSenderId: "1017652703912",
    appId: "1:1017652703912:web:46c93fba3702d1d729889d",
    measurementId: "G-XS5QC9150B"
  },
  firebaseAuth: {
    email: "db_admin@burningleads-dev.io",
    password: "08V@-Q3X[Usc"
  },
  sentry: {
    dsn: 'https://3c8e60b89875cda612bdb7a72ed46987@o4507724123209728.ingest.us.sentry.io/4507747485548544',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/15.188.49.127\/api/],
    ignoreErrors: []
  }
};
